$(document).ready(function () {
    const countriesMap = $('#countries'),
        mobileMediaQuery = window.matchMedia('(max-width: 767px)');

    function handleMobileChange(e) {
        (e.matches)  ? $(countriesMap).css('display', 'none') : $(countriesMap).removeAttr('style');
    }

    mobileMediaQuery.addEventListener('change', handleMobileChange);
    handleMobileChange(mobileMediaQuery);
});